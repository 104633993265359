<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
} from '@shopware-pwa/helpers-next';

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { navigationElements } = useNavigation();
const sideMenuController = useSideMenuModal();
const { count } = useCart();

const isMobileSearchActive = ref(false);
const isSideMenuOpen = ref(sideMenuController.isOpen);

const handleCloseSideMenu = () => {
  sideMenuController.close();
  isMobileSearchActive.value = false;
};

defineEmits(['openCartMenu']);
</script>

<template>
  <button
    v-if="!isSideMenuOpen"
    class="z-205 bg-transparent pl-2 lg:hidden"
    aria-label="Show side menu"
    @click="sideMenuController.open"
  >
    <div class="i-custom-svg:mobile-menu h-8 w-8" />
  </button>
  <button
    v-else
    class="z-205 bg-transparent lg:hidden"
    aria-label="Show side menu"
    @click="sideMenuController.close"
  >
    <div class="i-carbon-close h-8 w-8" />
  </button>
  <ClientOnly>
    <LayoutSidebar :controller="sideMenuController" side="left">
      <!-- Header -->
      <div class="sidebar-header flex items-center justify-between px-5 py-5">
        <button
          class="z-205 bg-transparent"
          aria-label="Show side menu"
          @click="handleCloseSideMenu"
        >
          <div class="i-carbon-close h-6 w-6" />
        </button>
        <NuxtLink :to="formatLink(`/`)" @click="sideMenuController.close">
          <span class="sr-only">SN super.natural Online Shop</span>
          <img v-lazy-load 
            class="h-10 w-10 lg:h-12 lg:w-12"
            data-src="/logo/sn_logo_schwarz.svg"
            alt="SN Logo"
            width="40"
            height="40"
          />
        </NuxtLink>
        <button
          class="group relative flex items-center bg-transparent"
          aria-label="Mini cart"
          @click="
            () => {
              handleCloseSideMenu();
              $emit('openCartMenu');
            }
          "
        >
          <div
            :class="
              count > 0 ? 'i-custom-svg:cart-filled' : 'i-custom-svg:cart'
            "
            class="text-dark hover:text-brand-primary h-6 w-6"
          />
          <span
            v-if="count > 0"
            class="text-3 font-sm absolute flex h-6 w-6 items-center justify-center pt-2 text-center text-white"
          >
            {{ count > 100 ? '99+' : count || '' }}
          </span>
          <span class="sr-only"
            >{{ $t('cart.itemsInCart') }}, {{ $t('cart.viewCart') }}</span
          >
        </button>
      </div>

      <aside
        aria-label="Sidebar"
        class="mobile-sidebar-menu pb-15 mt-5 h-full overflow-y-scroll px-3"
      >
        <div v-if="isMobileSearchActive" class="search-wrapper-mobile">
          <p class="pl-4 text-left text-2xl uppercase">
            {{ $t('sidebar.search') }}
          </p>
          <LayoutStoreSearch
            :is-mobile-search-active="isMobileSearchActive"
            is-mobile="true"
            @link-clicked="sideMenuController.close"
            @close-search="isMobileSearchActive = false"
          />
        </div>
        <div v-else class="flex h-full min-h-[650px] flex-col justify-between">
          <ZAvant :dynamic-height="false">
            <!-- LEVEL 1 -->
            <ZAvantItem
              v-for="navigationElement in navigationElements"
              :key="navigationElement.id"
              class="blocktext-left border-b-1 border-color-border-grey !mt-0"
            >
              <ZAvantMenu v-if="navigationElement?.children?.length > 0">
                <div class="submenu-title py-2 text-left font-bold">
                  {{ navigationElement.name }}
                </div>

                <template #next>
                  <span class="text-[18px] font-medium uppercase">{{
                    navigationElement.name
                  }}</span>
                </template>
                <template #back>
                  <span>Menü</span>
                </template>
                <!-- LEVEL 2 -->
                <ZAvantItem
                  v-for="(childElement, index) in navigationElement.children"
                  :key="childElement.id"
                  class="border-b-1 border-color-border-grey !mt-0 block text-left"
                >
                  <ZAvantMenu v-if="childElement?.children?.length > 0">
                    <div class="submenu-title py-2 text-left font-bold">
                      {{ childElement.name }}
                    </div>

                    <template #next>
                      <span class="text-[18px] font-medium uppercase">{{
                        childElement.name
                      }}</span>
                    </template>
                    <template #back>
                      <span>{{ navigationElement.name }}</span>
                    </template>
                    <!-- LEVEL 3 -->
                    <ZAvantItem
                      v-for="(
                        grandChildElement, index
                      ) in childElement.children"
                      :key="childElement.id"
                      class="border-b-1 border-color-border-grey !mt-0 block text-left"
                    >
                      <NuxtLink
                        :to="formatLink(getCategoryRoute(grandChildElement))"
                        :target="
                          grandChildElement.externalLink ||
                          grandChildElement.linkNewTab
                            ? '_blank'
                            : ''
                        "
                      >
                        <div
                          class="block"
                          :class="{
                            'max-w-200px md:max-w-300px':
                              !!grandChildElement.media,
                          }"
                          @click="sideMenuController.close"
                        >
                          <p class="text-[18px] font-medium text-gray-900">
                            {{
                              getTranslatedProperty(grandChildElement, 'name')
                            }}
                          </p>
                          <p
                            v-if="
                              getTranslatedProperty(
                                grandChildElement,
                                'description',
                              )
                            "
                            class="mt-1 text-sm text-gray-500"
                            v-html="
                              getTranslatedProperty(
                                grandChildElement,
                                'description',
                              )
                            "
                          />
                        </div>
                      </NuxtLink>
                    </ZAvantItem>
                  </ZAvantMenu>
                  <ZAvantItem v-else class="py-0">
                    <NuxtLink
                      role="menuitem"
                      :target="
                        childElement.externalLink || childElement.linkNewTab
                          ? '_blank'
                          : ''
                      "
                      :to="formatLink(getCategoryRoute(childElement))"
                      class="flex text-[18px] font-medium uppercase text-gray-900"
                      @click="sideMenuController.close"
                      >{{ childElement.name }}</NuxtLink
                    >
                  </ZAvantItem>
                </ZAvantItem>
              </ZAvantMenu>
              <ZAvantItem v-else class="py-0">
                <NuxtLink
                  role="menuitem"
                  :target="
                    navigationElement.externalLink ||
                    navigationElement.linkNewTab
                      ? '_blank'
                      : ''
                  "
                  :to="formatLink(getCategoryRoute(navigationElement))"
                  class="flex text-[18px] font-medium uppercase text-gray-900"
                  @click="sideMenuController.close"
                  >{{ navigationElement.name }}</NuxtLink
                >
              </ZAvantItem>
            </ZAvantItem>
          </ZAvant>

          <div class="mt-15 flex flex-col items-start gap-5">
            <!-- Account -->
            <AccountMenu :label="$t('sidebar.account')" />
            <!-- Wishlist -->
            <NuxtLink
              class="relative flex items-center gap-3"
              aria-label="wishlist"
              data-testid="wishlist-button"
              :to="formatLink(`/wishlist`)"
              @click="sideMenuController.close"
            >
              <div
                :class="
                  wishlistCount > 0
                    ? 'i-custom-svg:heart-filled'
                    : 'i-custom-svg:heart'
                "
                class="text-dark hover:text-brand-primary h-6 w-6"
              />
              <span
                v-if="wishlistCount > 0"
                class="text-3 font-sm absolute flex h-6 w-6 items-center justify-center pt-1 text-center text-white"
              >
                {{ wishlistCount > 100 ? '99+' : wishlistCount || '' }}
              </span>
              <span>{{ $t('sidebar.wishlist') }}</span>
            </NuxtLink>
            <!-- Search -->
            <button
              class="group relative flex items-center gap-3 bg-transparent"
              @click="isMobileSearchActive = true"
            >
              <div
                class="i-carbon-search text-dark hover:text-brand-primary h-6 w-6"
              />
              <span class="sr-only"
                >{{ $t('cart.itemsInCart') }}, {{ $t('cart.viewCart') }}</span
              >
              <span>{{ $t('sidebar.search') }}</span>
            </button>
          </div>
        </div>
      </aside>
    </LayoutSidebar>
  </ClientOnly>
</template>
<style lang="scss">
$chevron-size: 10px;
@mixin chevronStyle {
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-radius: 1px;
  width: $chevron-size;
  height: $chevron-size;
  border-color: var(--color-dark);
  content: '';
  display: inline-block;
}
.zavant__next:after {
  @include chevronStyle;
  position: absolute;
  transform: rotate(45deg);
  right: $chevron-size;
}

.zavant__back:before {
  @include chevronStyle;
  position: relative;
  transform: rotate(-135deg);
  left: calc($chevron-size / 2);
}

.zavant__item--back {
  border-bottom: 1px solid rgb(212, 212, 212);
}
</style>
